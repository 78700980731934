@import "../node_modules/ngx-bootstrap/datepicker/bs-datepicker.css";
@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "../node_modules/ngx-spinner/animations/line-spin-clockwise-fade.css";
@import "../node_modules/@fortawesome/fontawesome-free/css/all.css";
.tooltip-inner {
    border: 1px solid #650360;
    background-color: #650360 !important;
    color: #fff !important;
    font-family: Lato;
    font-size: 12px;
}
.tooltip-arrow:before,
.tooltip-arrow {
    border-right-color: #650360 !important;
}
